.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header
{
	background-color: white;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	font-size: calc(10px + 2vmin);
	color: #61dafb;
}

.App-link {
	color: #61dafb;
}

/* keeps logo centerd */
.nav-centered
{
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
}

.nav-centered:first-child
{
	justify-content: flex-start;
	flex-grow: 0;
}

.nav-centered:nth-child(2)
{
	justify-content: center;
	flex-grow: 1;
}


.nav-centered:last-child
{
	justify-content: flex-end;
	flex-grow: 0;
}




#menu
{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#menu > img
{
	width: 35px;
	padding: 10px;
}


#toolbar
{
	display: flex;
	justify-content: center;
	align-items: center;
}

#toolbar > button
{
	border-radius: 10px;
	width: 35px;
	height: 35px;
	background-color: #45B2F0;
	border: none;
	outline: none;
	margin-left: 5px;
	margin-right: 5px;
}

#toolbar > button > img
{
	width: 20px;
	height: 20px;
}

#header-logo
{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-decoration: none;
}

#header-logo > img
{
	height: 100%;
}

.logo-text
{
	margin-left: 10px;
	font-family: Pacifico;
	font-size: 30px;
	font-weight: 100;
	line-height: 30px;
	color: #45B2F0;
	text-transform: uppercase;
	margin-bottom: -3px;
}

.logo-text-sub
{
	font-family: Pacifico;
	font-size: 10px;
	color: #45B2F0;
	text-transform: uppercase;
	align-self: flex-end;
	padding-bottom: 7px;
}

#account
{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

#account > img
{
}

#account-button
{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* user icon */
#account-button > img:first-child
{
	height: 100%;
}

/* arrow down */
#account-button > img:last-child
{
	padding: 10px;
}

.account-popup-content
{

}


@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


#content
{
	height: calc(100vh - 50px);
	background-color: #282c34;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

/* @keyframes rotating
{
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
} */

.spinner
{
	/* animation: rotating 2s linear infinite; */
	transform: scale(0.8);
}